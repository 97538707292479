import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { invalidateRequests } from 'redux-bees'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ROUTES } from '../../constants'
import { formatCurrency, isPaid, urlFor } from 'utils'
import api from 'api'

export default connect(
  null,
  {
    invalidateRegistrations: () => async dispatch => {
      dispatch(invalidateRequests(api.getCampers))
    }
  }
)(class DashboardCamperRegistration extends React.Component {
  deleteRegistration(id) {
    api.deleteRegistration({ id: id }).then(() => this.props.invalidateRegistrations())
  }
  render() {
    const { registration, areaCamp: getAreaCamp, payments: getPayments, healthForm: getHealthForm } = this.props
    const areaCamp = getAreaCamp(registration)
    const payments = getPayments(registration)
    const healthForm = getHealthForm(registration)

    return (
      <Fragment>
        <td>{areaCamp ? areaCamp.attributes.name : <i>Missing area camp</i>}</td>
        {healthForm ? (
          <td>
            {healthForm.attributes.status} (
            <Link to={urlFor(ROUTES.healthForms.edit, { id: healthForm.id })}>edit</Link>)
          </td>
        ) : (
          <td>N/A</td>
        )}
        <td>
          {registration.attributes.waitlisted ? (
            'Waitlist'
          ) : registration.attributes.is_fully_paid ? (
            'Fully Paid'
          ) : (
            <Fragment>
              Paid: ${formatCurrency(registration.attributes.balance)}
              <br />
            </Fragment>
          )}
        </td>
        <td style={{ textAlign: 'right' }}>
          {registration.attributes.waitlisted ? (
            <Fragment>
              {areaCamp && areaCamp.attributes.active && (
                <Link to={urlFor(ROUTES.registrations.edit, { id: registration.id })} className="btn sm default">
                  <FontAwesomeIcon icon={['far', 'edit']} />
                </Link>
              )}
              <button
                onClick={() =>
                  window.location.href = 'mailto:camppayments@younglife.ca?subject=Cancel%20Registration%20Request'
                }
                className="btn sm danger"
              >
                {/* TODO: add real link */}
                <span>Cancel</span>
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {areaCamp && areaCamp.attributes.active && (
                <Link to={urlFor(ROUTES.registrations.edit, { id: registration.id })} className="btn sm gray">
                  <FontAwesomeIcon icon={['far', 'edit']} />
                </Link>
              )}
              {payments && payments.length === 0 && (
                <button
                  onClick={() =>
                    window.location.href = 'mailto:camppayments@younglife.ca?subject=Cancel%20Registration%20Request'
                  }
                  className="btn sm danger"
                >
                  {/* TODO: add real link */}
                  <span>Cancel</span>
                </button>
              )}
              {!isPaid(registration.attributes.balance, registration.attributes.fee) && (
                <Link
                  to={urlFor(ROUTES.payments.new, { id: registration.attributes['token'] })}
                  className="btn inline info"
                  style={{ fontSize: '1em', padding: '8px 16px' }}
                >
                  <span className="hide-mobile">Make Payment</span>
                  <span className="hide-desktop">Pay</span>
                </Link>
              )}
            </Fragment>
          )}
        </td>
      </Fragment>
    )
  }
})
